import { forwardRef } from "react";
import styled, { css } from "styled-components";

type InputVariant = "default" | "inline";

interface IInput {
  label: string;
  hasError?: boolean;
  variant?: InputVariant;
  [x: string]: any; // eslint-disable-line
}
const Input: React.FC<IInput> = (
  { label, hasError, variant = "default", ...rest },
  ref
) => {
  switch (variant) {
    case "default":
      return (
        <DefaultLabel isDisabled={rest.disabled}>
          <DefaultLabelText>{label}</DefaultLabelText>
          <DefautlInput ref={ref} hasError={hasError} {...rest} />
        </DefaultLabel>
      );
    case "inline":
      return (
        <InlineContainer isDisabled={rest.disabled}>
          <InlineInput
            ref={ref}
            hasError={hasError}
            {...rest}
            required={true}
            id={label}
          />
          <InlineLabel htmlFor={label}>{label}</InlineLabel>
        </InlineContainer>
      );
  }
};

const DefaultLabel = styled.label<{ isDisabled: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  opacity: ${(props) => (props.isDisabled ? "0.5" : "1")};
`;

const DefaultLabelText = styled.span`
  ${(props) => props.theme.typography.captionSmall};
  margin-bottom: ${(props) => props.theme.spacing.xxs};
`;

const DefautlInput = styled.input<{ hasError?: boolean }>`
  ${(props) => props.theme.typography.copy};
  outline: none;
  ${({ theme: { spacing, colors }, hasError }) => {
    return css`
      padding: 10px ${spacing.s};
      border-radius: ${spacing.xxs};
      border: solid 1px ${hasError ? colors.functionalRed : colors.darkGray};
      background-color: ${hasError ? "hsla(0, 100%, 43%, 0.03)" : colors.light};

      &:enabled:hover {
        background-color: ${hasError ? "hsl(0, 16%, 92%)" : colors.lightestGray};
      }

      &:enabled:active {
        padding: 9px ${spacing.s};
        background-color: ${hasError ? "hsl(0, 8%, 85%)" : colors.lightGray};
        border: solid 2px ${hasError ? colors.functionalRed : colors.functionalBlue};
      }

      &:enabled:focus {
        padding: 9px ${spacing.s};
        border: solid 2px ${hasError ? colors.functionalRed : colors.functionalBlue};
      }
    `;
  }}
`;

const InlineContainer = styled.div<{ isDisabled: boolean }>`
  width: 100%;
  position: relative;
  opacity: ${(props) => (props.isDisabled ? "0.5" : "1")};
`;

const InlineInput = styled.input<{ hasError: boolean }>`
  ${(props) => props.theme.typography.copy};
  outline: none;
  width: 100%;
  box-sizing: border-box;
  min-height: 54px;
  height: 0;
  ${({ theme: { spacing, colors }, hasError }) => {
    return css`
      padding: 20px ${spacing.s} 8px;
      border-radius: ${spacing.xxs};
      border: solid 1px ${hasError ? colors.functionalRed : colors.darkGray};
      background-color: ${hasError ? "hsla(0, 100%, 43%, 0.03)" : colors.light};

      &:enabled:hover {
        background-color: ${hasError ? "hsl(0, 16%, 92%)" : colors.lightestGray};
      }

      &:enabled:active {
        padding: 19px ${spacing.s} 7px;
        background-color: ${hasError ? "hsl(0, 8%, 85%)" : colors.lightGray};
        border: solid 2px ${hasError ? colors.functionalRed : colors.functionalBlue};
      }

      &:enabled:focus {
        outline: none;
        padding: 19px ${spacing.s} 7px;
        background-color: ${hasError ? "hsl(0, 8%, 85%)" : colors.lightGray};
        border: solid 2px ${hasError ? colors.functionalRed : colors.functionalBlue};
      }

      &:enabled:focus ~ label,
      &:valid ~ label {
        bottom: unset;
        top: ${(props) => props.theme.spacing.xxs};
      }
    `;
  }}
`;

const InlineLabel = styled.label`
  ${(props) => props.theme.typography.copySmall};
  color: ${(props) => props.theme.colors.darkestGray};
  position: absolute;
  pointer-events: none;
  bottom: ${(props) => props.theme.spacing.sm};
  left: ${(props) => props.theme.spacing.s};
`;

export default forwardRef<HTMLInputElement, IInput>(Input as any);
