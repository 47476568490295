import styled, { css } from "styled-components";
import Icon from "../../../../elements/smartevents/Icon";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { media } from "../../../../theme";

interface ILogo {
  translations: {
    logoTitle: string;
  };
}

const Logo: React.FC<ILogo> = ({ translations }) => {
  const { width } = useWindowDimensions();
  return (
    <LogoLink title={translations.logoTitle} href="/">
      {width > media.sizes.small ? (
        <LogoXl variant="logo-xl" aria-hidden="true" />
      ) : (
        <LogoSm variant="logo-sm" aria-hidden="true" />
      )}
    </LogoLink>
  );
};

const LogoXl = styled(Icon)`
  min-width: 342px;
  min-height: 78px;
`;

const LogoSm = styled(Icon)`
  min-width: 163px;
  min-height: 60px;
`;

const LogoSmls = styled(Icon)`
  min-width: 163px;
  min-height: 60px;
`;

const LogoLink = styled.a`
  all: unset;
  display: flex;
  align-items: center;
  position: relative;
  width: auto;

  &:focus::after {
    content: "";
    position: absolute;
    ${({ theme: { spacing, colors } }) => {
      return css`
        top: ${spacing.s};
        right: calc(${spacing.s} * -1);
        bottom: ${spacing.s};
        left: calc(${spacing.s} * -1);
        border: 2px solid ${colors.light};
        border-radius: ${spacing.xxs};
      `;
    }}
  }
`;

export default Logo;
