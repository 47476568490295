import { ReactComponent as Ticket } from "../../../assets/icons/smartevents/ticket.svg";
import { ReactComponent as FullPlus } from "../../../assets/icons/smartevents/full-plus.svg";
import { ReactComponent as Share } from "../../../assets/icons/smartevents/share.svg";
import { ReactComponent as Bookmark } from "../../../assets/icons/smartevents/bookmark.svg";
import { ReactComponent as Bookmarked } from "../../../assets/icons/smartevents/bookmarked.svg";
import { ReactComponent as NavigationLeft } from "../../../assets/icons/smartevents/navigation-left.svg";
import { ReactComponent as Info } from "../../../assets/icons/smartevents/info.svg";
import { ReactComponent as Facebook } from "../../../assets/icons/smartevents/facebook.svg";
import { ReactComponent as Linkedin } from "../../../assets/icons/smartevents/linkedin.svg";
import { ReactComponent as Twitter } from "../../../assets/icons/smartevents/twitter.svg";
import { ReactComponent as Xing } from "../../../assets/icons/smartevents/xing.svg";
import { ReactComponent as OnAir } from "../../../assets/icons/smartevents/on-air.svg";
import { ReactComponent as Play } from "../../../assets/icons/smartevents/play.svg";
import { ReactComponent as CircleSuccess } from "../../../assets/icons/smartevents/circle-success.svg";
import { ReactComponent as ProfilePicture } from "../../../assets/icons/smartevents/profile-picture.svg";
import { ReactComponent as AlertInformation } from "../../../assets/icons/smartevents/alert-information.svg";
import { ReactComponent as AlertWarning } from "../../../assets/icons/smartevents/alert-warning.svg";
import { ReactComponent as AlertHelp } from "../../../assets/icons/smartevents/alert-help.svg";
import { ReactComponent as X } from "../../../assets/icons/smartevents/x.svg";
import { ReactComponent as CollapseDown } from "../../../assets/icons/smartevents/collapse-down.svg";
import { ReactComponent as Audio } from "../../../assets/icons/smartevents/audio.svg";
import { ReactComponent as Download } from "../../../assets/icons/smartevents/download.svg";
import { ReactComponent as Upload } from "../../../assets/icons/smartevents/upload.svg";
import { ReactComponent as ExternalLink } from "../../../assets/icons/smartevents/external-link.svg";
import { ReactComponent as Pdf } from "../../../assets/icons/smartevents/pdf.svg";
import { ReactComponent as Ppt } from "../../../assets/icons/smartevents/ppt.svg";
import { ReactComponent as Video } from "../../../assets/icons/smartevents/videos.svg";
import { ReactComponent as File } from "../../../assets/icons/smartevents/file.svg";
import { ReactComponent as Www } from "../../../assets/icons/smartevents/www.svg";
import { ReactComponent as Zip } from "../../../assets/icons/smartevents/zip.svg";
import { ReactComponent as Email } from "../../../assets/icons/smartevents/email.svg";
import { ReactComponent as Phone } from "../../../assets/icons/smartevents/phone.svg";
import { ReactComponent as Changelog } from "../../../assets/icons/smartevents/changelog.svg";
import { ReactComponent as Search } from "../../../assets/icons/smartevents/search.svg";
import { ReactComponent as LogoXL } from "../../../assets/icons/smartevents/logo-xl.svg";
import { ReactComponent as LogoSM } from "../../../assets/icons/smartevents/logo-sm.svg";
import { ReactComponent as LogoSMLS } from "../../../assets/icons/smartevents/logo-sm-live-stage.svg";
import { ReactComponent as Logo } from "../../../assets/icons/smartevents/logo.svg";
import { ReactComponent as Menu } from "../../../assets/icons/smartevents/menu.svg";
import { ReactComponent as MenuUser } from "../../../assets/icons/smartevents/menu-user.svg";
import { ReactComponent as Globe } from "../../../assets/icons/smartevents/globe.svg";
import { ReactComponent as Logout } from "../../../assets/icons/smartevents/logout.svg";
import { ReactComponent as Edit } from "../../../assets/icons/smartevents/edit.svg";
import { ReactComponent as Selected } from "../../../assets/icons/smartevents/selected.svg";
import { ReactComponent as MenuPopupClosed } from "../../../assets/icons/smartevents/menu-popup-closed.svg";
import { ReactComponent as MenuPopupOpen } from "../../../assets/icons/smartevents/menu-popup-open.svg";
import { ReactComponent as Calendar } from "../../../assets/icons/smartevents/calendar.svg";
import { ReactComponent as OrderStatus } from "../../../assets/icons/smartevents/order-status.svg";
import { ReactComponent as Speakers } from "../../../assets/icons/smartevents/speakers.svg";
import { ReactComponent as MapPin } from "../../../assets/icons/smartevents/map-pin.svg";

import styled from "styled-components";

export type IconType =
  | "ticket"
  | "full-plus"
  | "share"
  | "bookmark"
  | "bookmarked"
  | "navigation-left"
  | "info"
  | "on-air"
  | "play"
  | "circle-success"
  | "profile-picture"
  | "alert-information"
  | "alert-help"
  | "alert-warning"
  | "x"
  | "collapse-down"
  | "calendar"
  | "audio"
  | "download"
  | "upload"
  | "selected"
  | "external-link"
  | "pdf"
  | "ppt"
  | "video"
  | "file"
  | "www"
  | "zip"
  | "email"
  | "phone"
  | "logo-sm"
  | "logo-sm-live-stage"
  | "logo-xl"
  | "logo"
  | "changelog"
  | "search"
  | "speakers"
  | "map-pin"
  | "menu"
  | "menu-user"
  | "menu-popup-closed"
  | "menu-popup-open"
  | "order-status"
  | "logout"
  | "globe"
  | "edit"
  | "facebook"
  | "linkedin"
  | "twitter"
  | "xing";

interface IIcon {
  variant: IconType;
  [x: string]: any; //eslint-disable-line
}

const Icon: React.FC<IIcon> = ({ variant, ...rest }) => {
  return <Container {...rest}>{getIconFromType(variant)}</Container>;
};

function getIconFromType(icon: IconType): React.ReactElement | null {
  switch (icon) {
    case "ticket":
      return <Ticket />;
    case "full-plus":
      return <FullPlus />;
    case "bookmark":
      return <Bookmark />;
    case "bookmarked":
      return <Bookmarked />;
    case "navigation-left":
      return <NavigationLeft />;
    case "share":
      return <Share />;
    case "info":
      return <Info />;
    case "on-air":
      return <OnAir />;
    case "play":
      return <Play />;
    case "circle-success":
      return <CircleSuccess />;
    case "profile-picture":
      return <ProfilePicture />;
    case "alert-information":
      return <AlertInformation />;
    case "alert-help":
      return <AlertHelp />;
    case "alert-warning":
      return <AlertWarning />;
    case "x":
      return <X />;
    case "collapse-down":
      return <CollapseDown />;
    case "calendar":
      return <Calendar />;
    case "audio":
      return <Audio />;
    case "download":
      return <Download />;
    case "upload":
      return <Upload />;
    case "external-link":
      return <ExternalLink />;
    case "pdf":
      return <Pdf />;
    case "ppt":
      return <Ppt />;
    case "video":
      return <Video />;
    case "file":
      return <File />;
    case "www":
      return <Www />;
    case "zip":
      return <Zip />;
    case "email":
      return <Email />;
    case "phone":
      return <Phone />;
    case "changelog":
      return <Changelog />;
    case "search":
      return <Search />;
    case "logo-sm":
      return <LogoSM />;
    case "logo-sm-live-stage":
      return <LogoSMLS />;
    case "logo-xl":
      return <LogoXL />;
    case "logo":
      return <Logo />;
    case "map-pin":
      return <MapPin />;
    case "menu":
      return <Menu />;
    case "logout":
      return <Logout />;
    case "globe":
      return <Globe />;
    case "edit":
      return <Edit />;
    case "menu-user":
      return <MenuUser />;
    case "menu-popup-closed":
      return <MenuPopupClosed />;
    case "menu-popup-open":
      return <MenuPopupOpen />;
    case "order-status":
      return <OrderStatus />;
    case "selected":
      return <Selected />;
    case "speakers":
      return <Speakers />;
    case "facebook":
      return <Facebook />;
    case "linkedin":
      return <Linkedin />;
    case "twitter":
      return <Twitter />;
    case "xing":
      return <Xing />;
    default:
      return null;
  }
}

const Container = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export default Icon;
