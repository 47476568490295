import Cookies from "js-cookie";
import base64 from 'react-native-base64';

export const getSignedCookie = (name: string): string => {
  const cookie = Cookies.get(name)
  if(cookie !== undefined){
    try{
      const payload = JSON.parse(base64.decode(cookie.split('--')[0]))
      const cookieValue = JSON.parse(base64.decode(payload._rails.message));
      return cookieValue;
    }
    catch(e){
      return null;
    }
  }
  return null;
};

export const getCookie = (name: string): string => {
  return Cookies.get(name);
}