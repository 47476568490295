import { useEffect, useState } from "react";
import styled from "styled-components";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";
import { media } from "../../../../../theme";
import LoginModal from "../../LoginModal";
import MenuList from "../MenuList";
import MenuItem from "../MenuList/MenuItem";
import Sidebar from "../Sidebar";
import SidebarButton from "../Sidebar/SidebarButton";
import SidebarList from "../Sidebar/SidebarList";

interface IMenuUnauthenticated {
  translations: {
    loginTitle: string;
    profileTitle: string;
    hamburgerTitle: string;
    languageTitle: string;
    registerModal: {
      paragraph: string;
      buttonText: string;
    };
    modalTitle: string;
    newPass: string;
  };
}

const MenuUnauthenticated: React.FC<IMenuUnauthenticated> = ({ translations }) => {
  const { width } = useWindowDimensions();
  const [showSidebar, setShowSidebar] = useState(false);
  const [userLanguage, setUserLanguage] = useState("");
  const [showLoginModal, setShowLoginModal] = useState(false);
  const isMediumWidthSize = width <= media.sizes.medium;

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("locale") === "de") {
      setUserLanguage("de");
    } else {
      setUserLanguage("en");
    }
    (window as any).openLoginModal = () => {
      setShowLoginModal(true);
    };
  }, []);

  return (
    <MenuList>
      {isMediumWidthSize ? (
        <>
          <MenuItem
            icon="menu"
            title={translations.hamburgerTitle}
            onClick={() => setShowSidebar(true)}
          />
          {showSidebar ? (
            <Sidebar handleClose={() => setShowSidebar(false)}>
              <SidebarList>
                <SidebarButton
                  variant="ghost"
                  icon="globe"
                  onClick={() => toggleLanguage(userLanguage)}
                >
                  <SidebarLanguage isBold={userLanguage === "en"}>
                    EN
                  </SidebarLanguage>
                  /
                  <SidebarLanguage isBold={userLanguage === "de"}>
                    DE
                  </SidebarLanguage>
                </SidebarButton>
                <SidebarButton
                  variant="ghost"
                  icon="menu-user"
                  onClick={() => {
                    setShowLoginModal(true);
                    setShowSidebar(false);
                  }}
                >
                  Login
                </SidebarButton>
              </SidebarList>
            </Sidebar>
          ) : null}
        </>
      ) : (
        <>
          <MenuItem
            title={translations.languageTitle}
            onClick={() => toggleLanguage(userLanguage)}
            text={
              <>
                <Language isBold={userLanguage === "en"}>EN</Language>/
                <Language isBold={userLanguage === "de"}>DE</Language>
              </>
            }
          />
          <MenuItem
            title={translations.loginTitle}
            onClick={() => {
              setShowLoginModal(true);
            }}
            icon="menu-user"
            text="Login"
          />
        </>
      )}
      {showLoginModal ? (
        <LoginModal handleClose={() => setShowLoginModal(false)}
          translations={{
            registerModal: translations.registerModal,
            modalTitle: translations.modalTitle,
            newPass: translations.newPass,
          }} />
      ) : null}
    </MenuList>
  );
};

const changeLanguage = (language: "en" | "de") => {
  const params = new URLSearchParams(window.location.search);
  const locale = params.get("locale");
  switch (language) {
    case "en":
      if (locale === "de") {
        params.set("locale", language);
        window.location.search = params.toString();
      }
      break;
    case "de":
      if (locale !== "de") {
        params.set("locale", language);
        window.location.search = params.toString();
      }
      break;
  }
};

const toggleLanguage = (currentLanguage: string) => {
  if (currentLanguage === "de") {
    changeLanguage("en");
  } else {
    changeLanguage("de");
  }
};

const Language = styled.span<{ isBold: boolean }>`
  ${(props) => props.theme.typography.navigation};
  font-weight: ${(props) => (props.isBold ? "bold" : "normal")};
  position: relative;
  top: 1px;
`;

const SidebarLanguage = styled.span<{ isBold: boolean }>`
  font-weight: ${(props) => (props.isBold ? "bold" : "normal")};
`;

export default MenuUnauthenticated;
