import { useState } from "react";
import { ThemeProvider } from "styled-components";
import API from "../../../../services/API";
import theme from "../../../../theme";
import IntroModal from "./IntroModal";
import SettingsModal from "./SettingsModal";
import { CookieConsentData } from "../../../../types/railsTypes";

interface ICookieConsent {
  data: CookieConsentData;
}

const CookieConsent: React.FC<ICookieConsent> = ({ data }) => {
  const [showIntroModal, setIntroModal] = useState(!data.cookieExist);
  const [showSettingsModal, setSettingsModal] = useState(false);

  const saveSettings = async ({ analytics, thirdParty }) => {
    await API.CookieConsent.set({ analytics: analytics, thirdParty: thirdParty });
    setIntroModal(false);
    setSettingsModal(false);
  };

  const closeSettingsModal = () => {
    setSettingsModal(false);
    setIntroModal(true);
  };

  const openSettingsModal = () => {
    setIntroModal(false);
    setSettingsModal(true);
  };

  return (
    <ThemeProvider theme={theme}>
      {showIntroModal ? (
        <IntroModal
          acceptAllHandler={() =>
            saveSettings({
              analytics: data.form.requireAnalytics ? true : false,
              thirdParty: true,
            })
          }
          openSettingsHandler={openSettingsModal}
          rejectAllHandler={() =>
            saveSettings({ analytics: false, thirdParty: false })
          }
          translations={data.introTranslations}
        />
      ) : null}
      {showSettingsModal ? (
        <SettingsModal
          acceptAllHandler={() =>
            saveSettings({
              analytics: data.form.requireAnalytics ? true : false,
              thirdParty: true,
            })
          }
          closeHandler={closeSettingsModal}
          formData={data.form}
          formImages={data.formImages}
          saveHandler={saveSettings}
          translations={data.settingsTranslations}
        />
      ) : null}
    </ThemeProvider>
  );
};

export default CookieConsent;
