import styled from "styled-components";
import { media } from "../../../../../theme";

interface IMenuList {
  [x: string]: any; // eslint-disable-line
}

const MenuList: React.FC<IMenuList> = (props) => {
  return <List {...props} />;
};

const List = styled.ul`
  grid-column: 7/12;
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  grid-column-gap: ${(props) => props.theme.spacing.l};
  color: ${(props) => props.theme.colors.light};
  margin: ${({ theme: { spacing } }) => `calc(${spacing.m} + 6px) 0 ${spacing.m}`};

  ${media.medium} {
    grid-column: 7/13;
  }

  ${media.small} {
    margin: ${({ theme: { spacing } }) => `${spacing.sm} 0 ${spacing.sm}`};
  }
`;

export default MenuList;
