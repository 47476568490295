import { css } from "styled-components";

const customMediaQuery = (maxWidth: number): string =>
  `@media (max-width: ${maxWidth}px)`;

const sizes = {
  large: 1439,
  medium: 999,
  small: 599,
};

export const media = {
  custom: customMediaQuery,
  large: customMediaQuery(sizes.large),
  medium: customMediaQuery(sizes.medium),
  small: customMediaQuery(sizes.small),
  sizes,
};

const theme = {
  colors: {
    primaryHue: "var(--basic--magenta-hue)",
    primarySaturation: "var(--basic--magenta-saturation)",
    primaryLightness: "var(--basic--magenta-lightness)",
    primary: "var(--basic--magenta)",
    secondaryHue: "var(--light--dark-blue-hue)",
    secondarySaturation: "var(--light--dark-blue-saturation)",
    secondaryLightness: "var(--light--dark-blue-lightness)",
    secondary: "var(--light--dark-blue)",
    dark: "var(--basic--gray)",
    light: "var(--basic--white)",
    functionalBlue: "var(--functional--blue)",
    functionalGreen: "var(--functional--green)",
    functionalYellow: "var(--functional--yellow)",
    functionalRed: "var(--functional--red)",
    darkestGray: "var(--light--gray-107)",
    darkGray: "var(--light--gray-178)",
    lightGray: "var(--light--gray-220)",
    lightestGray: "var(--light--gray-237)",
  },
  typography: {
    headline1: css`
      font-family: var(--font-family);
      font-size: 60px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;

      ${media.medium} {
        font-size: 48px;
      }
    `,
    headline2: css`
      font-family: var(--font-family);
      font-size: 48px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;

      ${media.medium} {
        font-size: 36px;
      }
    `,
    headline3: css`
      font-family: var(--font-family);
      font-size: 36px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;

      ${media.medium} {
        font-size: 28px;
        line-height: 1.07;
      }
    `,
    subtitle1: css`
      font-family: var(--font-family);
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;

      ${media.medium} {
        font-size: 20px;
        line-height: 1.2;
      }
    `,
    subtitle2: css`
      font-family: var(--font-family);
      font-size: 21px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;

      ${media.medium} {
        font-size: 18px;
        line-height: 1.22;
      }
    `,
    navigation: css`
      font-family: var(--font-family);
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
    `,
    caption: css`
      font-family: var(--font-family);
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
    `,
    captionSmall: css`
      font-family: var(--font-family);
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
    `,
    copy: css`
      font-family: var(--font-family);
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
    `,
    copySmallBold: css`
      font-family: var(--font-family);
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
    `,
    copySmall: css`
      font-family: var(--font-family);
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
    `,
    link: css`
      font-family: var(--font-family);
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
    `,
    linkSmall: css`
      font-family: var(--font-family);
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
    `,
    extraBoldClock: css`
      font-family: var(--font-family);
      font-size: 72px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.83;
      letter-spacing: normal;
      font-variant-numeric: tabular-nums;

      ${media.large} {
        font-size: 60px;
        line-height: 0.9;
      }
    `,
  },
  spacing: {
    xxxs: "var(--spacing-xxxs)",
    xxs: "var(--spacing-xxs)",
    xs: "var(--spacing-xs)",
    s: "var(--spacing-s)",
    sm: "var(--spacing-sm)",
    m: "var(--spacing-m)",
    l: "var(--spacing-l)",
    xl: "var(--spacing-xl)",
    xxl: "var(--spacing-xxl)",
    xxxl: "var(--spacing-xxxl)",
  },
  sizes,
};

export default theme;
