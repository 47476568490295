import styled from "styled-components";
import Button from "../../../../../elements/smartevents/Button";
import Input from "../../../../../elements/smartevents/Input";
import Link from "../../../../../elements/smartevents/Link";
import { media } from "../../../../../theme";
import { useForm } from "react-hook-form";
import { Dispatch, SetStateAction, useState } from "react";

interface ILoginSection {
  newPass: string;
  handleLoginClick: (
    email: string,
    password: string,
    setIsDisabled: Dispatch<SetStateAction<boolean>>
  ) => void;
}

interface FormData {
  email: string;
  password: string;
}

const LoginSection: React.FC<ILoginSection> = ({ newPass, handleLoginClick }) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = ({ email, password }: FormData) => {
    setIsDisabled(true);
    handleLoginClick(email, password, setIsDisabled);
  };

  return (
    <Section onSubmit={handleSubmit(onSubmit)}>
      <EmailInput
        label="Email"
        type="email"
        hasError={errors.email}
        {...register("email", { required: true })}
      />
      <PasswordInput
        label="Password"
        type="password"
        autocomplete="off"
        hasError={errors.password}
        {...register("password", { required: true })}
      />
      <ActionContainer>
        <StyledButton variant="outline" type="submit" disabled={isDisabled}>
          Login
        </StyledButton>
        <StyledLink title="Reset password" href="/new-pass" variant="captionSmall">
          {newPass}
        </StyledLink>
      </ActionContainer>
    </Section>
  );
};

const Section = styled.form`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  ${media.medium} {
    flex-direction: column;
  }
`;

const StyledLink = styled(Link)`
  margin-left: ${(props) => props.theme.spacing.m};

  ${media.medium} {
    merign-left: 0;
  }
`;

const EmailInput = styled(Input)`
  margin-bottom: ${(props) => props.theme.spacing.s};
`;

const PasswordInput = styled(Input)`
  margin-bottom: ${(props) => props.theme.spacing.m};
`;

const StyledButton = styled(Button)`
  ${media.medium} {
    width: 100%;
    margin-bottom: ${(props) => props.theme.spacing.s};
  }
`;

export default LoginSection;
