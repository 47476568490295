import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Icon from "../../../../../elements/smartevents/Icon";
import { media } from "../../../../../theme";
import FocusTrap from "focus-trap-react";

interface IAdminMenu {
  translations: {
    labels: {
      events: string;
      contents: string;
    };
    myEvents: string;
    approval: string;
    videos: string;
    speakers: string;
    locations: string;
  };
  userRights: {
    admin: boolean;
    products: boolean;
    reviewer: boolean;
    videos: boolean;
    speakers: boolean;
    locations: boolean;
  };
}

const AdminMenu: React.FC<IAdminMenu> = ({ translations, userRights }) => {
  const [isPopup, setIsPopup] = useState(false);

  const menuRef = useRef();
  closePopup(menuRef, () => setIsPopup(false));

  const handleKeypress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      setIsPopup(true);
    }
  };

  return (
    <div>
      <MenuIcon
        tabIndex={1}
        variant={isPopup ? "menu-popup-open" : "menu-popup-closed"}
        onClick={() => setIsPopup((v) => !v)}
        onKeyPress={handleKeypress}
      />
      {isPopup ? (
        <FocusTrap>
          <PopupContainer ref={menuRef} tabIndex={0}>
            <Label>{translations.labels.events}</Label>
            {userRights.products ? (
              <ContentContainer
                onClick={() => window.location.assign("/easy-cms/events")}
              >
                <Icon variant="calendar" />
                <TextSection>{translations.myEvents}</TextSection>
              </ContentContainer>
            ) : null}
            {userRights.reviewer ? (
              <OrderStatusContainer
                onClick={() => window.location.assign("/easy-cms/events/approval")}
              >
                <Icon variant="order-status" />
                <TextSection>{translations.approval}</TextSection>
              </OrderStatusContainer>
            ) : null}

            <Label>{translations.labels.contents}</Label>
            {userRights.videos ? (
              <ContentContainer
                onClick={() => window.location.assign("/cms_app/videos")}
              >
                <Icon variant="play" />
                <TextSection>{translations.videos}</TextSection>
              </ContentContainer>
            ) : null}
            {userRights.speakers ? (
              <ContentContainer
                onClick={() => window.location.assign("/contact_people")}
              >
                <Icon variant="speakers" />
                <TextSection>{translations.speakers}</TextSection>
              </ContentContainer>
            ) : null}
            {userRights.locations ? (
              <LocationSpacer onClick={() => window.location.assign("/locations")}>
                <Icon variant="map-pin" />
                <TextSection>{translations.locations}</TextSection>
              </LocationSpacer>
            ) : null}
          </PopupContainer>
        </FocusTrap>
      ) : null}
    </div>
  );
};

const closePopup = (ref, handler) => {
  useEffect(() => {
    const effect = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        handler();
      } else if (e.key === "Escape") {
        handler();
      }
    };

    document.addEventListener("mousedown", effect);
    document.addEventListener("keydown", effect);

    return () => {
      document.removeEventListener("mousedown", effect);
      document.removeEventListener("keydown", effect);
    };
  }, [ref, handler]);
};

const MenuIcon = styled(Icon)`
  width: 24px;
  height: 24px;

  ${media.medium} {
    display: none;
  }
`;

const PopupContainer = styled.div`
  position: absolute;
  top: 66px;
  left: -24px;
  width: max-content;
  padding: 24px 36px 12px 24px;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1), 0 16px 64px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  z-index: 99;
`;

const Label = styled.span`
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 1px;
  color: #6b6b6b;
  font-family: TeleNeo, TeleNeoWeb, sans-serif;
`;

const ContentContainer = styled.button`
  margin: 12px 0 12px 0;
  padding: 12px 0;
  display: flex;
  border: none;
  background: none;
  font-family: TeleNeo, TeleNeoWeb, sans-serif;
  &:focus {
    outline-color: var(--functional--blue);
  }
`;

const TextSection = styled.span`
  margin-left: 11px;
  transform: rotate(-360deg);
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  color: #262626;
  position: relative;
  top: 1px;
`;

const OrderStatusContainer = styled(ContentContainer)`
  margin: 12px 0 24px 0;
`;

const LocationSpacer = styled(ContentContainer)`
  padding-bottom: 12px;
  margin-bottom: 0;
`;
export default AdminMenu;
