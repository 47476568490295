import { useRef } from "react";
import styled, { css } from "styled-components";
import Icon from "../Icon";
import { forwardRef } from "react";
import useCombinedRefs from "../../../hooks/useCombinedRefs";

interface ICheckbox {
  label: string;
  defaultChecked?: boolean;
  [x: string]: any; // eslint-disable-line
}

const Checkbox: React.FC<ICheckbox> = (
  { label, className, ...rest },
  forwardedRef
) => {
  const innerRef = useRef(null);
  const combinedRef = useCombinedRefs(forwardedRef, innerRef);

  const setCheckedInput = () => {
    innerRef.current.click();
  };

  return (
    <Label
      tabIndex={0}
      className={className}
      onKeyPress={(e) => {
        if (e.key === " " || e.key === "Enter") {
          setCheckedInput();
        }
      }}
    >
      <input
        ref={combinedRef}
        style={{ display: "none" }}
        type="checkbox"
        {...rest}
      />
      <SelectedContainer>
        <SelectedIcon variant="selected" />
      </SelectedContainer>
      <LabelText>{label}</LabelText>
    </Label>
  );
};

const SelectedContainer = styled.div`
  min-width: 24px;
  width: 0;
  min-height: 24px;
  height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  ${({ theme: { colors } }) => {
    return css`
      border: 1px solid ${colors.darkGray};
      background-color: ${colors.lightestGray};
    `;
  }}
`;

const SelectedIcon = styled(Icon)`
  width: 15px;
  height: 12px;
  color: ${(props) => props.theme.colors.functionalGreen};
  opacity: 0;
`;

const LabelText = styled.span`
  ${(props) => props.theme.typography.copySmall};
  margin-left: ${(props) => props.theme.spacing.s};
`;

const Label = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  outline: none;
  > input:checked + div > ${SelectedIcon} {
    opacity: 1;
  }

  &:hover > div {
    background-color: ${(props) => props.theme.colors.lightGray};
  }

  &:active > div {
    background-color: ${(props) => props.theme.colors.lightGray};
    border-color: ${(props) => props.theme.colors.functionalBlue};
    border-width: 2px;
  }

  &:focus > div {
    border-color: ${(props) => props.theme.colors.functionalBlue};
    border-width: 2px;
  }
`;

export default forwardRef<HTMLInputElement, ICheckbox>(Checkbox as any);
