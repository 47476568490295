import styled from "styled-components";
import Button from "../../../../../elements/smartevents/Button";
import Icon from "../../../../../elements/smartevents/Icon";

interface IRegisterSection {
  translations: {
    paragraph: string;
    buttonText: string;
  };
}
const RegisterSection: React.FC<IRegisterSection> = ({translations}) => {
  return (
    <Section>
      <ProfileIcon variant="profile-picture" />
      <Paragraph>
        {translations.paragraph}
      </Paragraph>
      <Button
        variant="cta"
        onClick={() => {
          window.location.assign("/users/sign_up");
        }}
      >
        {translations.buttonText}
      </Button>
    </Section>
  );
};

const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ProfileIcon = styled(Icon)`
  width: 84px;
  height: 84px;
`;

const Paragraph = styled.p`
  ${(props) => props.theme.typography.copy};
  margin: ${({ theme: { spacing } }) => `${spacing.m} 0`};
  text-align: center;
  max-width: 35ch;
`;

export default RegisterSection;
