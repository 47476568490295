import axios from "axios";
import { Quiz, QuizData, QuizResult } from "../types/railsTypes";

const tokenElement: Element & { content: string } =
  document.querySelector("[name=csrf-token]");
const token = tokenElement ? tokenElement.content : null;
axios.defaults.headers.common["X-CSRF-Token"] = token;
axios.defaults.headers.common["Accept"] = "application/json";

const API = {
  CookieConsent: {
    set: async ({
      analytics,
      thirdParty,
    }: {
      analytics: boolean;
      thirdParty: boolean;
    }): Promise<boolean> => {
      return await axios
        .post(`/api/cookies`, {
          cookie_form: {
            analytics: analytics,
            third_party: thirdParty,
          },
        })
        .then((res) => res.data);
    },
  },
  TrackingConsent: {
    set: async (user_tracking_consent: boolean): Promise<{ error: string }> => {
      return await axios
        .post("/api/tracking_consents", { user: { user_tracking_consent } })
        .then((res) => res.data);
    },
  },
  Product: {
    toggleBookmark: async (id: number): Promise<boolean> => {
      return await axios
        .post(`/api/products/${id}/bookmark`)
        .then((res) => res.data.bookmarked);
    },
  },
  Polls: {
    publish: async (data): Promise<boolean> => {
      return await axios
        .post("/api/poll_results/poll_report_result", {
          result: data,
        })
        .then((res) => res.data);
    },
  },
  Ticket: {
    book: async (ids: number[]): Promise<void> => {
      return await axios.post("/api/tickets/book", { ids }).then((res) => res.data);
    },
  },
  User: {
    signIn: async (email: string, password: string): Promise<{ error: string }> => {
      return await axios
        .post("/users/sign_in", { user: { email, password } })
        .then((res) => res.data);
    },
    signOut: async (): Promise<void> => {
      return await axios.delete("/users/sign_out").then((res) => res.data);
    },
    updateProfile: async (fields: Record<string, string>[]): Promise<void> => {
      return await axios.patch("/api/profile", { fields }).then((res) => {
        sessionStorage.setItem("current-user-token", res.data.token);
      });
    },
  },
  Quiz: {
    get: async (id: string, lang: string): Promise<Quiz> => {
      return await axios
        .get(`/cms_app/api/v1/self_quiz/quizzes/${id}?language=${lang}`, {
          headers: {
            "X-CSRF-Token": undefined,
            AuthorizationUserToken: sessionStorage.getItem("current-user-token"),
          },
        })
        .then((res) => res.data);
    },
    post: async (quizResult: QuizResult): Promise<QuizData> => {
      return await axios
        .post("/api/v1/self_quiz/results", quizResult, {
          headers: {
            accept: "application/json",
            authorization: `Bearer ${sessionStorage.getItem("current-user-token")}`,
          },
        })
        .then((res) => res.data);
    },
    queryResult: async (id: string): Promise<QuizData> => {
      return await axios
        .get(`/api/v1/self_quiz/results/${id}`, {
          headers: {
            accept: "application/json",
            authorization: `Bearer ${sessionStorage.getItem("current-user-token")}`,
          },
        })
        .then((res) => res.data);
    },
    redo: async (id: string): Promise<number> => {
      return await axios
        .put(
          `/api/v1/self_quiz/quizzes/${id}/redo`,
          {},
          {
            headers: {
              accept: "application/json",
              authorization: `Bearer ${sessionStorage.getItem(
                "current-user-token"
              )}`,
            },
          }
        )
        .then((res) => res.data);
    },
  },
};

export default API;
