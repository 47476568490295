import styled, { ThemeProvider } from "styled-components";
import Grid from "../../../../elements/smartevents/Grid";
import theme, { media } from "../../../../theme";
import { HeaderData } from "../../../../types/railsTypes";
import Logo from "../Logo";
import MenuAuthenticated from "./MenuAuthenticated";
import MenuUnauthenticated from "./MenuUnauthenticated";
import AdminMenu from "./AdminMenu";

interface IHeader {
  userRights: {
    admin: boolean;
    products: boolean;
    reviewer: boolean;
    videos: boolean;
    speakers: boolean;
    locations: boolean;
  };
  isAuthenticated: boolean;
  data: HeaderData;
  userId: number;
  translations: {
    adminMenu: {
      labels: {
        events: string;
        contents: string;
      };
      myEvents: string;
      approval: string;
      videos: string;
      speakers: string;
      locations: string;
    };
    logoTitle: string;
    loginTitle: string;
    profileTitle: string;
    hamburgerTitle: string;
    languageTitle: string;
    myListTitle: string;
    myListText: string;
    updateSuccess: string;
    updateError: string;
    someonesProfilePicture: string;
    uploadPhoto: string;
    allowedFormats: string;
    emailSettings: string;
    cancel: string;
    confirm: string;
    personalInformation: string;
    edit: string;
    logout: string;
    deleteAccount: string;
    registerModal: {
      paragraph: string;
      buttonText: string;
    };
    modalTitle: string;
    newPass: string;
    dataNotice?: string;
  };
}

const Header: React.FC<IHeader> = ({
  userRights,
  isAuthenticated,
  userId,
  data,
  translations,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <Nav>
        <Grid>
          {userRights.admin ? (
            <AdminMenuContainer>
              <AdminMenu
                translations={translations.adminMenu}
                userRights={userRights}
              />
            </AdminMenuContainer>
          ) : null}
          <LogoContainer>
            <Logo translations={translations} />
          </LogoContainer>
          {isAuthenticated ? (
            <MenuAuthenticated
              data={data}
              userId={userId}
              translations={translations}
            />
          ) : (
            <MenuUnauthenticated translations={translations} />
          )}
        </Grid>
      </Nav>
    </ThemeProvider>
  );
};

const Nav = styled.nav`
  background-color: ${(props) => props.theme.colors.primary};
  border-bottom: ${({ theme: { colors, spacing } }) =>
    `${spacing.xxs} solid ${colors.light}`};
  margin-left: auto;
  margin-right: auto;
  max-width: 1980px;
`;

const LogoContainer = styled.div`
  grid-column-start: 2;
  display: flex;

  ${media.medium} {
    grid-column-start: 1;
  }
`;

const AdminMenuContainer = styled.div`
  display: flex;
  position: relative;
  right: 21px;
  align-items: center;
`;

export default Header;
