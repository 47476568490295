import styled, { css } from "styled-components";

interface IMenuLink {
  title: string;
  children: React.ReactNode;
  onClick?: () => void;
  [x: string]: any; //eslint-disable-line
}

const MenuLink: React.FC<IMenuLink> = (props) => {
  return (
    <Link
      tabIndex={0}
      {...props}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
      onKeyDown={(e) => {
        if (props.onClick && (e.key === "Enter" || e.key === " ")) {
          props.onClick();
        }
      }}
    />
  );
};

const Link = styled.a`
  all: unset;
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  position: relative;
  width: auto;

  &:hover,
  &:focus {
    color: inherit;
    outline: none;
  }

  &:focus::after {
    content: "";
    position: absolute;
    ${({ theme: { spacing, colors } }) => {
      return css`
        top: calc(${spacing.s} * -1);
        right: calc(${spacing.s} * -1);
        bottom: calc(${spacing.s} * -1);
        left: calc(${spacing.s} * -1);
        border: 2px solid ${colors.light};
        border-radius: ${spacing.xxs};
      `;
    }}
  }
`;

export default MenuLink;
