import React, { DOMAttributes, useMemo } from "react";
import camelcaseKeys from "camelcase-keys";
import useCustomElement from "../../../hooks/useCustomElement";
import { ICustomHeader } from "../../../types/railsTypes";

type CustomEvents<K extends string> = { [key in K]: (event: CustomEvent) => void };
type CustomElement<T, K extends string> = Partial<
  T & DOMAttributes<T> & { children: any } & CustomEvents<`on${K}`>
>;
// eslint-disable-next-line
declare global {
  // eslint-disable-next-line
  namespace JSX {
    interface IntrinsicElements {
      ["web-header"]: CustomElement<any, "closeChange" | "openChange">;
    }
  }
}

const CustomHeader: React.FC<{
  customHeader: ICustomHeader;
}> = ({ customHeader }) => {
  const webProps = useMemo(() => {
    return camelcaseKeys(
      {
        ...customHeader,
        modals: Object.values(customHeader.modals),
      },
      { deep: true }
    );
  }, [customHeader]);
  const [customElementProps, ref] = useCustomElement({ data: webProps });
  return <web-header ref={ref} {...customElementProps}></web-header>;
};

export default CustomHeader;
