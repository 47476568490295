import styled from "styled-components";
import { media } from "../../../../../../theme";

interface ISidebarList {
  [x: string]: any; // eslint-disable-line
}

const SidebarList: React.FC<ISidebarList> = (props) => {
  return <List {...props} />;
};

const List = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  position: relative;
  padding-right: ${(props) => props.theme.spacing.xxl};

  ${media.small} {
    padding-right: ${(props) => props.theme.spacing.l};
  }
`;

export default SidebarList;
