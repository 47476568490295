import GridColumns from "./GridColumns";
import GridWrapper from "./GridWrapper";

interface IGrid {
  children: React.ReactNode;
  [x: string]: any; //eslint-disable-line
}

const Grid: React.FC<IGrid> = ({ children, ...rest }) => {
  return (
    <GridWrapper {...rest}>
      <GridColumns>{children}</GridColumns>
    </GridWrapper>
  );
};

export default Grid;
