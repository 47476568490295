import DOMPurify from "dompurify";
import { useEffect } from "react";
import styled from "styled-components";
import Button from "../../../../../elements/smartevents/Button";
import Modal from "../../../../../elements/smartevents/Modal";
import { TrackingConsentTranslations } from "../../../../../types/railsTypes";

interface ITrackingModal {
  acceptHandler: () => void;
  rejectHandler: () => void;
  translations: TrackingConsentTranslations;
}

const TrackingModal: React.FC<ITrackingModal> = ({
  acceptHandler,
  rejectHandler,
  translations,
}) => {

  return (
    <StyledModal>
      <ModalTitle>{translations.title}</ModalTitle>
      <ModalContent
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(translations.description),
        }}
      />
      <ButtonsContainer>
        <Button type="button" variant="outline" onClick={rejectHandler}>
          {translations.noButton}
        </Button>
        <Button variant="cta" type="submit" onClick={acceptHandler}>
          {translations.yesButton}
        </Button>
      </ButtonsContainer>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  height: max-content;
`;

const ModalTitle = styled.h3`
  ${(props) => props.theme.typography.headline3};
  margin: 0 0 ${(props) => props.theme.spacing.m};
`;

const ModalContent = styled.div`
  ${(props) => props.theme.typography.copySmall};
  p {
    margin: ${(props) => props.theme.spacing.s} 0;
  }
  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.functionalBlue};
  }
  strong {
    font-weight: bold;
  }
  ol {
    padding-left: ${(props) => props.theme.spacing.s};
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing.s};
  justify-content: right;
  margin-top: ${(props) => props.theme.spacing.m};
`;

export default TrackingModal;
