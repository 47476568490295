import { useState } from "react";
import styled, { ThemeProvider, css } from "styled-components";
import Grid from "../../../../elements/smartevents/Grid";
import Modal from "../../../../elements/smartevents/Modal";
import theme, { media } from "../../../../theme";
import { FooterData } from "../../../../types/railsTypes";
import MenuLink from "../Header/MenuList/MenuItem/MenuLink";
import Icon from "../../../../elements/smartevents/Icon";

interface IFooter {
  data: FooterData;
  translations: {
    logoTitle: string;
  };
}

const Footer: React.FC<IFooter> = ({ data, translations }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({ data: "", title: "" });

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Grid>
          <Content>
            <LogoLink title={translations.logoTitle} href="/">
              <Logo variant="logo" aria-hidden="true" />
            </LogoLink>

            <Copyright>
              {data.layout.right_side.text || "© T-Systems International GmbH"}
            </Copyright>
            <DataList>
              {data.modals.map((modal, index) => (
                <DataItem key={index}>
                  <MenuLink
                    title={modal.title}
                    onClick={() => {
                      setModalData({ data: modal.text, title: modal.title });
                      setShowModal(true);
                    }}
                  >
                    {modal.title}
                  </MenuLink>
                </DataItem>
              ))}
            </DataList>
          </Content>
        </Grid>
        {showModal ? (
          <Modal handleModalClose={() => setShowModal(false)}>
            <ModalTitle>{modalData.title}</ModalTitle>
            <ModalContent dangerouslySetInnerHTML={{ __html: modalData.data }} />
          </Modal>
        ) : null}
      </Container>
    </ThemeProvider>
  );
};

const Container = styled.footer`
  background-color: ${(props) => props.theme.colors.dark};
  color: ${(props) => props.theme.colors.light};
`;

const Content = styled.div`
  ${(props) => props.theme.typography.copySmall}
  grid-column: 2/12;
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  padding: ${({ theme: { spacing } }) => `${spacing.m} 0`};
  ${media.medium} {
    grid-column: 1/13;
    grid-template-columns: 1fr;
    padding: ${({ theme: { spacing } }) => `${spacing.m} 0 ${spacing.xl}`};
  }
`;

const Copyright = styled.p`
  margin: 0 ${(props) => props.theme.spacing.s};
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.medium} {
    margin: ${({ theme: { spacing } }) => `${spacing.s} 0 ${spacing.l}`};
    justify-content: flex-start;
  }
`;

const DataList = styled.ul`
  all: unset;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${media.medium} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const DataItem = styled.li`
  cursor: pointer;
  &:not(:first-child) {
    margin-left: ${(props) => props.theme.spacing.m};
  }

  ${media.medium} {
    &:last-child {
      margin-left: 0;
    }
    &:not(:last-child) {
      margin-left: 0;
      margin-bottom: ${(props) => props.theme.spacing.m};
    }
  }
`;

const ModalTitle = styled.h3`
  ${(props) => props.theme.typography.headline3};
  margin: 0 0 ${(props) => props.theme.spacing.m};
`;

const ModalContent = styled.div`
  ${(props) => props.theme.typography.copySmall};
  p {
    margin: ${(props) => props.theme.spacing.s} 0;
  }
  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.functionalBlue};
  }
  strong {
    font-weight: bold;
  }
  ol {
    padding-left: ${(props) => props.theme.spacing.s};
  }
`;

const LogoLink = styled.a`
  all: unset;
  display: flex;
  align-items: center;
  position: relative;
  width: auto;

  &:focus::after {
    content: "";
    position: absolute;
    ${({ theme: { spacing, colors } }) => {
      return css`
        top: ${spacing.s};
        right: calc(${spacing.s} * -1);
        bottom: ${spacing.s};
        left: calc(${spacing.s} * -1);
        border: 2px solid ${colors.light};
        border-radius: ${spacing.xxs};
      `;
    }}
  }
`;

const Logo = styled(Icon)`
  min-width: 87px;
  min-height: 43px;
`;

export default Footer;
