import { useEffect, useRef } from "react";
import styled from "styled-components";
import Icon from "../../../../../elements/smartevents/Icon";
import { media } from "../../../../../theme";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import FocusTrap from "focus-trap-react";
import Portal from "../../../../../elements/smartevents/Portal";

interface ISidebar {
  handleClose: () => void;
  showLine?: boolean;
  children: React.ReactNode;
  [x: string]: any; //eslint-disable-line
}

const Sidebar: React.FC<ISidebar> = ({
  handleClose,
  showLine = true,
  children,
  ...rest
}) => {
  const sidebarRef = useRef();

  useEffect(() => {
    const targetElement = sidebarRef.current;
    if (targetElement) {
      disableBodyScroll(targetElement);
      const close = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
          handleClose();
        }
      };
      window.addEventListener("keydown", close);
    }

    return () => {
      clearAllBodyScrollLocks();
      window.removeEventListener("keydown", close);
    };
  }, []);

  return (
    <Portal>
      <FocusTrap>
        <Container role="dialog" aria-modal="true" ref={sidebarRef} {...rest}>
          <CloseIcon
            variant="x"
            onClick={handleClose}
            tabIndex={0}
            role="button"
            onKeyPress={(e: KeyboardEvent) => {
              if (e.key === " " || e.key === "Enter") {
                handleClose();
              }
            }}
          />
          {showLine ? <SpacerLine id="profile_spacer" /> : null}
          {children}
        </Container>
      </FocusTrap>
    </Portal>
  );
};

const Container = styled.div`
  position: fixed;
  z-index: 96;
  top: 0;
  bottom: 0;
  right: 0;
  padding-left: ${(props) => props.theme.spacing.xxl};
  box-shadow: 0 8px 16px 0 hsla(0, 0%, 0%, 0.1), 0 16px 64px 0 hsla(0, 0%, 0%, 0.1);
  background-color: ${(props) => props.theme.colors.light};
  max-width: 780px;
  width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;

  ${media.medium} {
    max-width: unset;
    width: auto;
    left: ${(props) => props.theme.spacing.l};
  }

  ${media.small} {
    left: ${(props) => props.theme.spacing.sm};
    padding-left: ${(props) => props.theme.spacing.l};
  }
`;

const CloseIcon = styled(Icon)`
  position: absolute;
  top: ${(props) => props.theme.spacing.l};
  right: ${(props) => props.theme.spacing.l};
  width: 24px;
  height: 24px;
  cursor: pointer;
  box-sizing: border-box;

  &:active,
  &:focus {
    outline: none;
    border: 2px solid ${(props) => props.theme.colors.functionalBlue};
    border-radius: ${(props) => props.theme.spacing.xxs};
  }

  ${media.small} {
    top: ${(props) => props.theme.spacing.sm};
    right: ${(props) => props.theme.spacing.sm};
  }
`;

const SpacerLine = styled.div`
  width: auto;
  height: 1px;
  background-color: ${(props) => props.theme.colors.lightGray};
  margin-top: ${({ theme: { spacing } }) =>
    `calc(${spacing.l} + 24px + ${spacing.m})`};
  margin-bottom: ${(props) => props.theme.spacing.sm};

  ${media.small} {
    margin-top: ${({ theme: { spacing } }) =>
      `calc(${spacing.sm} + 24px + ${spacing.sm})`};
  }
`;

export default Sidebar;
