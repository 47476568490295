import DOMPurify from "dompurify";
import styled from "styled-components";
import Checkbox from "../../../../../elements/smartevents/Checkbox";

interface IPreference {
  checkboxValue: boolean;
  imageActiveSrc: string;
  imageInactiveSrc: string;
  onChangeHandler?: (event) => void;
  paragraph1: string;
  paragraph2: string;
  title: string;
}

const Preference: React.FC<IPreference> = ({
  checkboxValue,
  imageActiveSrc,
  imageInactiveSrc,
  onChangeHandler,
  paragraph1,
  paragraph2,
  title,
}) => {
  return (
    <PreferenceWrapper>
      {checkboxValue ? (
        <PreferenceImageWrapper>
          <img alt="" src={imageActiveSrc} />
        </PreferenceImageWrapper>
      ) : null}
      {!checkboxValue ? (
        <PreferenceImageWrapper>
          <img alt="" src={imageInactiveSrc} />
        </PreferenceImageWrapper>
      ) : null}
      <PreferenceContent>
        <PreferenceHeader>{title}</PreferenceHeader>
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(paragraph1) }} />
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(paragraph2) }} />
      </PreferenceContent>
      <PreferenceCheckboxWrapper>
        <Checkbox
          disabled={!onChangeHandler}
          checked={checkboxValue}
          onChange={onChangeHandler}
        />
      </PreferenceCheckboxWrapper>
    </PreferenceWrapper>
  );
};

const PreferenceWrapper = styled.div`
  align-items: center;
  display: flex;
`;
const PreferenceImageWrapper = styled.div`
  flex: 0 0 80px;
`;
const PreferenceContent = styled.div`
  margin: 15px 30px;
  flex: 0 1 auto;
`;
const PreferenceHeader = styled.h4``;
const PreferenceCheckboxWrapper = styled.div`
  flex: 0 0 auto;
`;

export default Preference;
