import styled, { css } from "styled-components";
import { media } from "../../../theme";
import Icon from "../Icon";
import { useEffect, useRef } from "react";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import FocusTrap from "focus-trap-react";
import Portal from "../Portal";

interface IModal {
  children: React.ReactNode;
  handleModalClose?: () => void;
  [x: string]: any; // eslint-disable-line
}

const Modal: React.FC<IModal> = ({ children, handleModalClose, ...rest }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const targetElement = modalRef.current;
    if (targetElement) {
      disableBodyScroll(targetElement);
      const close = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
          handleModalClose();
        }
      };
      window.addEventListener("keydown", close);
    }

    return () => {
      clearAllBodyScrollLocks();
      window.removeEventListener("keydown", close);
    };
  }, []);

  return (
    <Portal>
      <Mask />
      <FocusTrap>
        <Box onClick={handleModalClose} ref={modalRef}>
          <Spacer />
          <Container
            role="dialog"
            aria-modal="true"
            {...rest}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {handleModalClose ? (
              <CloseIcon
                variant="x"
                onClick={handleModalClose}
                tabIndex={0}
                role="button"
                onKeyPress={(e: KeyboardEvent) => {
                  if (e.key === " " || e.key === "Enter") {
                    handleModalClose();
                  }
                }}
              />
            ) : null}
            {children}
          </Container>
          <Spacer />
        </Box>
      </FocusTrap>
    </Portal>
  );
};

const Mask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.dark};
  opacity: 0.5;
  z-index: 98;
`;

const Box = styled.div`
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: auto;
  display: grid;
  overflow: scroll;
  ${({ theme: { spacing } }) => {
    return css`
      grid-template-columns: ${spacing.m} minmax(0, 1fr) ${spacing.m};
      ${media.small} {
        grid-template-columns: ${spacing.s} minmax(0, 1fr) ${spacing.s};
      }
    `;
  }}
`;

const Spacer = styled.div`
  min-width: ${(props) => props.theme.spacing.m};
  ${media.small} {
    min-width: ${(props) => props.theme.spacing.s};
  }
`;

const Container = styled.div`
  position: relative;
  justify-self: center;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1), 0 16px 64px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  width: 100%;
  ${({ theme: { spacing, colors } }) => {
    return css`
      background-color: ${colors.light};
      border-radius: ${spacing.xs};
      margin: ${spacing.xxl} 0;
      padding: ${spacing.xl} ${spacing.xxxl} ${spacing.xxxl};
      max-width: min(648px, 100%);

      ${media.medium} {
        padding: ${spacing.xl} ${spacing.xxl} ${spacing.xxl};
        margin: ${spacing.xl} 0;
      }

      ${media.small} {
        padding: ${spacing.l} ${spacing.m};
        margin: ${spacing.m} 0;
      }
    `;
  }}
`;

const CloseIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  ${({ theme: { spacing, colors } }) => {
    return css`
      position: absolute;
      top: ${spacing.m};
      right: ${spacing.m};
      cursor: pointer;
      box-sizing: border-box;

      &:active,
      &:focus {
        outline: none;
        border: 2px solid ${colors.functionalBlue};
        border-radius: ${spacing.xxs};
      }

      ${media.small} {
        top: ${spacing.s};
        right: ${spacing.s};
      }
    `;
  }}
`;

export default Modal;
