import styled from "styled-components";
import Icon from "../../../../elements/smartevents/Icon";
import { IconType } from "../../../../elements/smartevents/Icon/Icon";
import Link from "../../../../elements/smartevents/Link";

export type AlertVariant = "error" | "warning" | "info" | "success";

interface IAlert {
  variant: AlertVariant;
  text: string;
  link?: {
    text: string;
    to: string;
  };
  [x: string]: any; //eslint-disable-line
}

const Alert: React.FC<IAlert> = ({ variant, text, link, ...rest }) => {
  return (
    <Container alertVariant={variant} {...rest}>
      <AlertIcon alertVariant={variant} variant={getIconType(variant)} />
      <AlertText>{text}</AlertText>
      {link ? <AlertLink href={link.to}>{link.text}</AlertLink> : null}
    </Container>
  );
};

const getIconType = (variant: AlertVariant): IconType => {
  switch (variant) {
    case "success":
      return "circle-success";
    case "error":
      return "alert-information";
    case "warning":
      return "alert-warning";
    case "info":
      return "alert-information";
  }
};

const Container = styled.div<{ alertVariant: AlertVariant }>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: ${(props) => props.theme.spacing.xxs};
  padding: ${({ theme: { spacing } }) => `${spacing.sm} ${spacing.m} ${spacing.m}`};
  box-sizing: border-box;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.05;
    background-color: ${({ theme: { colors }, alertVariant }) => {
      switch (alertVariant) {
        case "success":
          return colors.functionalGreen;
        case "error":
          return colors.functionalRed;
        case "warning":
          return colors.functionalYellow;
        case "info":
          return colors.functionalBlue;
      }
    }};
  }
`;

const AlertIcon = styled(Icon)<{ alertVariant: AlertVariant }>`
  width: 30px;
  height: 30px;
  color: ${({ theme: { colors }, alertVariant }) => {
    switch (alertVariant) {
      case "success":
        return colors.functionalGreen;
      case "error":
        return colors.functionalRed;
      case "warning":
        return colors.functionalYellow;
      case "info":
        return colors.functionalBlue;
    }
  }};
`;

const AlertText = styled.p`
  ${(props) => props.theme.typography.copy};
  text-align: center;
  width: 100%;
  font-weight: 500;
`;

const AlertLink = styled(Link)`
  color: ${(props) => props.theme.colors.dark};
  text-decoration: underline;
  width: 100%;
  text-align: center;
`;

export default Alert;
