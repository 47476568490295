import { useState } from "react";
import styled, { css } from "styled-components";
import GridColumns from "../../../../elements/smartevents/Grid/GridColumns";
import Modal from "../../../../elements/smartevents/Modal";
import API from "../../../../services/API";
import { media } from "../../../../theme";
import Alert from "../Alert";
import { AlertVariant } from "../Alert/Alert";
import LoginSection from "./LoginSection";
import RegisterSection from "./RegisterSection";

interface ILoginModal {
  handleClose: () => void;
  translations: {
    registerModal: {
      paragraph: string;
      buttonText: string;
    };
    modalTitle: string;
    newPass: string;
  };
  notification?: {
    variant: AlertVariant;
    text: string;
    link?: {
      text: string;
      to: string;
    };
  };
  redirectToBook?: boolean;
}

const LoginModal: React.FC<ILoginModal> = ({
  handleClose,
  notification,
  translations,
  redirectToBook = false,
}) => {
  const [alertNotification, setAlertNotification] = useState(notification);
  return (
    <AutoModal handleModalClose={handleClose}>
      <Container>
        <Title>{translations.modalTitle}</Title>
        <AreaContainer>
          <LeftSide>
            {alertNotification ? (
              <Alert {...alertNotification} />
            ) : (
              <RegisterSection translations={translations.registerModal} />
            )}
          </LeftSide>
          <Line />
          <RightSide>
            <LoginSection
              newPass={translations.newPass}
              handleLoginClick={async (email, password, setIsDisabled) => {
                try {
                  await API.User.signIn(email, password);
                  redirectToBook
                    ? window.location.assign(window.location.href + "/book")
                    : window.location.reload();
                } catch (e) {
                  setAlertNotification({
                    variant: "error",
                    text: e.response.data?.error,
                    link: e.response.data?.link,
                  });

                  setIsDisabled(false);
                }
              }}
            />
          </RightSide>
        </AreaContainer>
      </Container>
    </AutoModal>
  );
};

const AutoModal = styled(Modal)`
  align-self: center;
  min-width: 756px;
  ${({ theme: { spacing } }) => {
    return css`
      padding: ${spacing.xl} ${spacing.xl} ${spacing.xxl};

      ${media.medium} {
        padding: ${spacing.l} ${spacing.l} ${spacing.xl};
        min-width: unset;
      }

      ${media.small} {
        padding: ${spacing.l} ${spacing.l} ${spacing.xl};
        max-width: calc(100% - 2 * ${spacing.s});
      }
    `;
  }}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  ${(props) => props.theme.typography.headline3};
  margin: 0 0 ${(props) => props.theme.spacing.xl};
  width: 100%;
  text-align: center;

  ${media.medium} {
    margin-bottom: ${(props) => props.theme.spacing.l};
  }
`;

const AreaContainer = styled(GridColumns)`
  padding: ${(props) => props.theme.spacing.s} 0;
`;

const LeftSide = styled.div`
  grid-column: 1/6;
  display: flex;
  align-items: center;

  ${media.medium} {
    justify-content: center;
    grid-column: 1/13;
  }
`;

const RightSide = styled.div`
  grid-column: 8/13;
  display: flex;
  align-items: center;

  ${media.medium} {
    justify-content: center;
    grid-column: 1/13;
  }
`;

const Line = styled.div`
  margin: ${({ theme: { spacing } }) => `calc(${spacing.s} * -1) 0`};
  background-color: ${(props) => props.theme.colors.lightGray};
  width: 1px;
  grid-column: 6/8;
  justify-self: center;

  ${media.medium} {
    width: 100%;
    height: 1px;
    grid-column: 1/13;
    margin: ${({ theme: { spacing } }) => `${spacing.m} 0`};
  }
`;

export default LoginModal;
