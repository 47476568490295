import styled, { css } from "styled-components";

type LinkVariant = "caption" | "captionSmall" | "copy" | "copySmall";

export interface ILink {
  variant: LinkVariant;
  children: React.ReactNode;
  [x: string]: any; // eslint-disable-line
}

const Link: React.FC<ILink> = ({ variant, children, ...rest }) => {
  return (
    <StyledLink variant={variant} {...rest}>
      <FontSize variant={variant}>{children}</FontSize>
    </StyledLink>
  );
};

const StyledLink = styled.a<{ variant: LinkVariant }>`
  ${({ variant, theme: { spacing, colors } }) => {
    return css`
      position: relative;
      color: ${colors.secondary};
      text-decoration: none;
      outline: none;
      cursor: pointer;

      &:hover {
        text-decoration: ${variant === "copy" || variant === "copySmall"
          ? "underline"
          : "none"};
        color: hsl(
          ${colors.secondaryHue},
          ${colors.secondarySaturation},
          calc(${colors.secondaryLightness} - 4%)
        );
      }

      &:active {
        text-decoration: none;
        color: hsl(
          ${colors.secondaryHue},
          ${colors.secondarySaturation},
          calc(${colors.secondaryLightness} - 7%)
        );
      }

      &:focus::after {
        content: "";
        position: absolute;
        top: calc(${spacing.xxs} * -1);
        bottom: calc(${spacing.xxs} * -1);
        left: calc(${spacing.xs} * -1);
        right: calc(${spacing.xs} * -1);
        border: 2px solid ${colors.functionalBlue};
        border-radius: ${spacing.xxs};
        padding: ${spacing.xxs} ${spacing.xs};
      }
    `;
  }}
`;

const FontSize = styled.span<{ variant: LinkVariant }>`
  position: relative;
  top: 1px;
  ${(props) => {
    switch (props.variant) {
      case "copy":
        return props.theme.typography.copy;
      case "copySmall":
        return props.theme.typography.copySmall;
      case "caption":
        return props.theme.typography.caption;
      case "captionSmall":
        return props.theme.typography.captionSmall;
      default:
        return props.theme.typography.captionSmall;
    }
  }};
`;

export default Link;
