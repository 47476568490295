import { useState } from "react";
import styled from "styled-components";
import Button from "../../../../../elements/smartevents/Button";
import Modal from "../../../../../elements/smartevents/Modal";
import {
  CookieConsentFormData,
  CookieConsentFormImages,
  CookieConsentSettingsTranslations,
} from "../../../../../types/railsTypes";
import Preference from "./Preference";

interface ISettingsModal {
  acceptAllHandler: () => void;
  closeHandler: () => void;
  formData: CookieConsentFormData;
  formImages: CookieConsentFormImages;
  translations: CookieConsentSettingsTranslations;
  saveHandler: (settings) => void;
}

const SettingsModal: React.FC<ISettingsModal> = ({
  acceptAllHandler,
  closeHandler,
  formData,
  formImages,
  translations,
  saveHandler,
}) => {
  const [allowAnalytics, setAllowAnalytics] = useState(formData.analytics);
  const allowRequired = true;
  const [allowThirdParty, setAllowThirdParty] = useState(formData.thirdParty);
  return (
    <StyledModal handleModalClose={closeHandler}>
      <ModalTitle>{translations.title}</ModalTitle>
      <ModalContent dangerouslySetInnerHTML={{ __html: translations.content }} />
      <ModalContent>
        <Preference
          checkboxValue={allowRequired}
          imageActiveSrc={formImages.requiredActive}
          imageInactiveSrc={formImages.requiredInactive}
          paragraph1={translations.required.paragraph1}
          paragraph2={translations.required.paragraph2}
          title={translations.required.title}
        />
        {formData.requireAnalytics ? (
          <Preference
            checkboxValue={allowAnalytics}
            imageActiveSrc={formImages.analyticsActive}
            imageInactiveSrc={formImages.analyticsInactive}
            onChangeHandler={(event) => setAllowAnalytics(event.target.checked)}
            paragraph1={translations.analytics.paragraph1}
            paragraph2={translations.analytics.paragraph2}
            title={translations.analytics.title}
          />
        ) : null}
        <Preference
          checkboxValue={allowThirdParty}
          imageActiveSrc={formImages.thirdPartyActive}
          imageInactiveSrc={formImages.thirdPartyInactive}
          onChangeHandler={(event) => setAllowThirdParty(event.target.checked)}
          paragraph1={translations.thirdParty.paragraph1}
          paragraph2={translations.thirdParty.paragraph2}
          title={translations.thirdParty.title}
        />
      </ModalContent>

      <ButtonsContainer>
        <Button
          type="button"
          variant="outline"
          onClick={() =>
            saveHandler({ analytics: allowAnalytics, thirdParty: allowThirdParty })
          }
        >
          {translations.saveSettingsButton}
        </Button>
        <Button variant="cta" type="submit" onClick={acceptAllHandler}>
          {translations.acceptAllButton}
        </Button>
      </ButtonsContainer>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  height: max-content;
`;

const ModalTitle = styled.h3`
  ${(props) => props.theme.typography.headline3};
  margin: 0 0 ${(props) => props.theme.spacing.m};
`;

const ModalContent = styled.div`
  ${(props) => props.theme.typography.copySmall};
  p {
    margin: ${(props) => props.theme.spacing.s} 0;
  }
  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.functionalBlue};
  }
  strong {
    font-weight: bold;
  }
  ol {
    padding-left: ${(props) => props.theme.spacing.s};
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing.s};
  justify-content: right;
  margin-top: ${(props) => props.theme.spacing.m};
`;

export default SettingsModal;
