import styled, { css } from "styled-components";
import { media } from "../../../../theme";

interface IGridWrapper {
  children: React.ReactNode;
  spacerStyles?: any;
  [x: string]: any;
}

const GridWrapper: React.FC<IGridWrapper> = ({
  children,
  spacerStyles,
  ...rest
}) => {
  return (
    <Wrapper {...rest}>
      <Spacer style={spacerStyles} />
      {children}
      <Spacer style={spacerStyles} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  ${({ theme: { sizes } }) => {
    return css`
      max-width: ${sizes.large}px;
    `;
  }}
`;

const Spacer = styled.div`
  width: 0;
  min-width: 60px;
  background-color: transparent;

  ${media.medium} {
    min-width: 36px;
  }

  ${media.small} {
    min-width: 18px;
  }
`;

export default GridWrapper;
