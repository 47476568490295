import { useEffect, useRef, useState, Fragment } from "react";
import styled from "styled-components";
import Button from "../../../../elements/smartevents/Button";
import Checkbox from "../../../../elements/smartevents/Checkbox";
import Input from "../../../../elements/smartevents/Input";
import Link from "../../../../elements/smartevents/Link";
import Select from "../../../../elements/smartevents/Select";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import API from "../../../../services/API";
import { media } from "../../../../theme";
import { UserField } from "../../../../types/railsTypes";
import Alert from "../Alert";
import Sidebar from "../Header/Sidebar";
import { useForm } from "react-hook-form";
import produce from "immer";
import DOMPurify from "dompurify";

interface IProfile {
  user: UserField[];
  userId: number;
  handleClose: () => void;
  translations: {
    updateSuccess: string;
    updateError: string;
    someonesProfilePicture: string;
    uploadPhoto: string;
    allowedFormats: string;
    emailSettings: string;
    cancel: string;
    confirm: string;
    personalInformation: string;
    edit: string;
    logout: string;
    deleteAccount: string;
    dataNotice?: string;
  };
}

const Profile: React.FC<IProfile> = ({
  user,
  userId,
  handleClose,
  translations,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [userObject, setUserObject] = useState({});
  const [name, setName] = useState("");
  const [editable, setEditable] = useState(false);
  const [alertNotification, setAlertNotification] = useState({
    variant: "",
    text: "",
  });
  const [avatarSrc, setAvatarSrc] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const uploadRef = useRef<HTMLInputElement>();
  const openUpload = () => {
    if (uploadRef.current) {
      uploadRef.current.click();
    }
  };

  const onSubmit = async (fields: Record<string, string>[]) => {
    setIsDisabled(true);
    try {
      await API.User.updateProfile(fields);
      setEditable(false);
      setAlertNotification({
        variant: "success",
        text: translations.updateSuccess,
      });
      const data = produce(userObject, (draft) => {
        for (const [key, value] of Object.entries(fields)) {
          draft[key].value = value;
        }
      });

      window.location.reload();
      setIsDisabled(false);
      setUserObject(data);
    } catch (e) {
      setIsDisabled(false);
      setAlertNotification({
        variant: "error",
        text: translations.updateError,
      });
    }
  };

  const { width } = useWindowDimensions();

  useEffect(() => {
    setUserObject(() => {
      const usrObj = {};
      for (const field of user) {
        usrObj[field.id] = field;
      }
      return usrObj;
    });
  }, [user]);

  useEffect(() => {
    if (Object.entries(userObject).length === 0) return;
    setName(
      `${userObject["first_name"].value ?? ""} ${
        userObject["last_name"].value ?? ""
      }`
    );
    setAvatarSrc(userObject["avatar"].value ?? "");
  }, [userObject]);

  if (Object.entries(userObject).length === 0) return null;
  return (
    <ProfileSidebar handleClose={handleClose} showLine={width < media.sizes.medium}>
      <Content tabIndex={-1} onSubmit={handleSubmit(onSubmit)}>
        <HeaderContainer>
          <ProfileImage
            src={avatarSrc}
            alt={`${name}${translations.someonesProfilePicture}`}
          />
          {editable ? (
            <>
              <Button
                type="button"
                variant="outline"
                icon="upload"
                onClick={openUpload}
              >
                {translations.uploadPhoto}
              </Button>
              <AllowedFormats>{translations.allowedFormats}</AllowedFormats>
              <input
                style={{ display: "none" }}
                ref={uploadRef}
                type="file"
                name="image"
                accept="image/*"
                onChange={(e) => {
                  const file = e.target.files[0];
                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onloadend = function () {
                    setAvatarSrc([reader.result] as any);
                    setValue("avatar", reader.result);
                  };
                }}
              />
            </>
          ) : (
            <>
              <Name>{`${
                userObject["salutation"].values.find(
                  (v) => v.id === userObject["salutation"]?.value
                )?.label ?? ""
              } ${name}`}</Name>
              <Email>{userObject["email"]?.value ?? ""}</Email>
            </>
          )}
          {alertNotification.variant ? (
            <ChangeAlert
              variant={alertNotification.variant}
              text={alertNotification.text}
            />
          ) : null}
        </HeaderContainer>
        <SectionHeader>
          <SectionTitle>{translations.personalInformation}</SectionTitle>
          <SectionLine />
          {!editable ? (
            <Button
              variant="ghost"
              icon="edit"
              onClick={() => {
                setAlertNotification({ variant: "", text: "" });
                setEditable(true);
              }}
            >
              {translations.edit}
            </Button>
          ) : null}
        </SectionHeader>
        {editable ? (
          <EditableGroup>
            <IdentityData>
              <SelectContainer>
                <Select
                  items={userObject["salutation"].values}
                  label={userObject["salutation"].label}
                  defaultValue={userObject["salutation"].value}
                  {...register("salutation", { required: true })}
                />
              </SelectContainer>
              <Input
                variant="inline"
                label={userObject["first_name"].label}
                defaultValue={userObject["first_name"].value}
                hasError={errors.first_name}
                autoComplete="given-name"
                {...register("first_name", { required: true })}
              />
              <Input
                variant="inline"
                label={userObject["last_name"].label}
                defaultValue={userObject["last_name"].value}
                hasError={errors.last_name}
                autoComplete="family-name"
                {...register("last_name", { required: true })}
              />
            </IdentityData>
            <GeneralData>
              {user
                .filter((field) => {
                  if (!field.visible) return false;
                  switch (field.id) {
                    case "first_name":
                    case "last_name":
                    case "avatar":
                    case "salutation":
                    case "send_message_updates_email":
                    case "email":
                      return false;
                    default:
                      return true;
                  }
                })
                .map((field) =>
                  field.values.length > 0 ? (
                    <Select
                      key={field.id}
                      items={field.values}
                      label={field.label}
                      defaultValue={field.value}
                      {...register(field.id, { required: field.required })}
                    />
                  ) : (
                    <Input
                      key={field.id}
                      variant="inline"
                      label={field.label}
                      defaultValue={field.value ?? ""}
                      hasError={errors[field.id]}
                      autoComplete="on"
                      {...register(field.id, { required: field.required })}
                    />
                  )
                )}
            </GeneralData>
          </EditableGroup>
        ) : (
          <InfoContainer>
            {user
              .filter((field) => {
                if (!field.visible) return false;
                switch (field.id) {
                  case "first_name":
                  case "last_name":
                  case "avatar":
                  case "salutation":
                  case "send_message_updates_email":
                  case "email":
                    return false;
                  default:
                    return true;
                }
              })
              .map((field) => (
                <Fragment key={field.id}>
                  {field.values.length > 0 ? (
                    <InfoData>
                      <InfoLabel>{field.label}</InfoLabel>
                      <InfoText>
                        {userObject[field.id].values?.find(
                          (v) => v.id === userObject[field.id].value
                        )?.label ?? ""}
                      </InfoText>
                    </InfoData>
                  ) : (
                    <InfoData>
                      <InfoLabel>{field.label}</InfoLabel>
                      <InfoText>{userObject[field.id].value ?? ""}</InfoText>
                    </InfoData>
                  )}
                </Fragment>
              ))}
          </InfoContainer>
        )}
        {editable &&
        !!userObject["send_message_updates_email"] &&
        userObject["send_message_updates_email"].visible ? (
          <>
            <SectionHeader>
              <SectionTitle>{translations.emailSettings}</SectionTitle>
              <SectionLine />
            </SectionHeader>
            {
              <StyledCheckbox
                label={userObject["send_message_updates_email"].label ?? ""}
                defaultChecked={!!userObject["send_message_updates_email"].value}
                {...register("send_message_updates_email")}
              />
            }
          </>
        ) : null}
        {translations.dataNotice ? (
          <NoticeArea
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(translations.dataNotice),
            }}
            hasMargin={!editable}
          ></NoticeArea>
        ) : null}
      </Content>
      <ActionBar>
        {editable ? (
          <>
            <Button
              variant="outline"
              onClick={() => {
                setEditable(false);
                setIsDisabled(false);
                setAvatarSrc(userObject["avatar"].value ?? "");
              }}
            >
              {translations.cancel}
            </Button>
            <Button
              disabled={isDisabled}
              variant="cta"
              type="submit"
              onClick={() => {
                handleSubmit(onSubmit)();
              }}
            >
              {translations.confirm}
            </Button>
          </>
        ) : (
          <>
            <Link
              variant="copy"
              href={`/educate_app/users/${userId}/confirm_deactivate`}
            >
              {translations.deleteAccount}
            </Link>
            {width > media.sizes.medium ? (
              <Button
                variant="ghost"
                icon="logout"
                onClick={async () => {
                  await API.User.signOut();
                  window.location.href = window.location.origin + "/";
                }}
              >
                {translations.logout}
              </Button>
            ) : null}
          </>
        )}
      </ActionBar>
    </ProfileSidebar>
  );
};

const ProfileSidebar = styled(Sidebar)`
  padding: 0;
  display: flex;
  flex-direction: column;

  > #profile_spacer {
    margin-left: ${({ theme: { spacing } }) => spacing.l};
  }
`;

const Content = styled.form`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  column-gap: 12px;
  align-content: start;
  padding: ${({ theme: { spacing } }) => `0 ${spacing.xxxl}`};
  overflow-y: auto;

  ${media.medium} {
    padding: ${({ theme: { spacing } }) => `0 ${spacing.l}`};
  }
`;

const HeaderContainer = styled.div`
  grid-column: 1/5;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: ${({ theme: { spacing } }) => `${spacing.xxl} 0 0`};

  ${media.medium} {
    margin: 0;
  }
`;

const ProfileImage = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: ${(props) => props.theme.spacing.m};

  ${media.medium} {
    margin-bottom: ${(props) => props.theme.spacing.s};
  }
`;

const Name = styled.h1`
  ${(props) => props.theme.typography.headline3};
  margin: ${({ theme: { spacing } }) => `0 0 ${spacing.xs}`};
  text-align: center;

  ${media.medium} {
    margin: ${({ theme: { spacing } }) => `0 0 ${spacing.xxs}`};
  }
`;

const Email = styled.p`
  ${(props) => props.theme.typography.copy};
  margin: ${({ theme: { spacing } }) => `0 0 ${spacing.xxl}`};
  text-align: center;

  ${media.medium} {
    margin: ${({ theme: { spacing } }) => `0 0 ${spacing.xl}`};
  }
`;

const AllowedFormats = styled.p`
  ${(props) => props.theme.typography.copySmall};
  margin: ${({ theme: { spacing } }) => `${spacing.s} 0 ${spacing.xxl}`};
  text-align: center;

  ${media.medium} {
    margin: ${({ theme: { spacing } }) => `${spacing.s} 0 ${spacing.xl}`};
  }
`;

const ChangeAlert = styled(Alert)`
  margin: ${({ theme: { spacing } }) => `0 0 ${spacing.xxl}`};

  ${media.medium} {
    margin: ${({ theme: { spacing } }) => `0 0 ${spacing.xl}`};
  }
`;

const SectionHeader = styled.div`
  grid-column: 1/5;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacing.l};

  ${media.medium} {
    grid-template-columns: 1fr auto;
    margin-bottom: ${(props) => props.theme.spacing.m};
  }
`;

const SectionTitle = styled.h2`
  ${(props) => props.theme.typography.subtitle2};
  margin: 0;
  padding: ${({ theme: { spacing } }) => `${spacing.s} 0 `};
`;

const SectionLine = styled.div`
  background-color: ${(props) => props.theme.colors.lightGray};
  width: auto;
  height: 1px;
  margin: ${({ theme: { spacing } }) => `0 ${spacing.m}`};

  ${media.medium} {
    grid-column: 1/3;
    margin: 0;
    order: 1;
  }
`;

const InfoContainer = styled.div`
  grid-column: 1/5;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: ${(props) => props.theme.spacing.m};

  ${media.medium} {
    grid-row-gap: ${(props) => props.theme.spacing.sm};
    grid-template-columns: 1fr;
  }
`;

const InfoData = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoLabel = styled.p`
  ${(props) => props.theme.typography.copySmall};
  color: ${(props) => props.theme.colors.darkestGray};
  margin: 0;
`;

const InfoText = styled.p`
  ${(props) => props.theme.typography.copy};
  margin: 0;
`;

const ActionBar = styled.div`
  width: 100%;
  align-self: stretch;
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${(props) => props.theme.colors.lightGray};
  padding: ${({ theme: { spacing } }) => `${spacing.m} ${spacing.xxxl}`};
  background-color: ${(props) => props.theme.colors.light};
  box-sizing: border-box;

  ${media.medium} {
    max-width: unset;
    width: auto;
    left: ${(props) => props.theme.spacing.l};
    padding: ${({ theme: { spacing } }) => `${spacing.m} ${spacing.l}`};
  }

  ${media.small} {
    left: ${(props) => props.theme.spacing.sm};
  }
`;

const EditableGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-column: 1/5;
`;

const IdentityData = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${(props) => props.theme.spacing.s};
  margin-bottom: ${(props) => props.theme.spacing.l};

  ${media.medium} {
    grid-template-columns: 1fr;
    margin-bottom: ${(props) => props.theme.spacing.m};
  }
`;

const SelectContainer = styled.div`
  grid-column: 1/3;

  ${media.medium} {
    grid-column: unset;
  }
`;

const GeneralData = styled.div`
  margin-bottom: ${(props) => props.theme.spacing.xxl};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${(props) => props.theme.spacing.s};

  ${media.medium} {
    grid-template-columns: 1fr;
    margin-bottom: ${(props) => props.theme.spacing.xl};
  }
`;

const StyledCheckbox = styled(Checkbox)`
  grid-column: 1/5;
  padding-bottom: ${(props) => props.theme.spacing.xxl};
  margin-top: ${({ theme: { spacing } }) => `calc(${spacing.s} * -1)`};
  ${media.medium} {
    padding-bottom: ${(props) => props.theme.spacing.xl};
    margin-top: 0;
  }
`;

const NoticeArea = styled.div<{ hasMargin: boolean }>`
  ${(props) => props.theme.typography.copySmall}
  grid-column: 1/5;
  margin-top: ${(props) => (props.hasMargin ? props.theme.spacing.m : 0)};
`;

export default Profile;
