import { ThemeProvider } from "styled-components";
import API from "../../../../services/API";
import theme from "../../../../theme";
import TrackingModal from "./TrackingModal";
import { TrackingConsentTranslations } from "../../../../types/railsTypes";


interface ITrackingConsent {
  translations: TrackingConsentTranslations;
}

const TrackingConsent: React.FC<ITrackingConsent> = ({ translations  }) => {
  const saveSettings = async (user_tracking_consent: boolean) => {
    await API.TrackingConsent.set(user_tracking_consent);
    window.location.reload();
  };
  return (
    <ThemeProvider theme={theme}>
      <TrackingModal
        acceptHandler={() =>
          saveSettings(true)
        }
        rejectHandler={() =>
          saveSettings(false)
        }
        translations={translations}
      />
    </ThemeProvider>
  );
};

export default TrackingConsent;
