import { useState, useEffect } from "react";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";
import API from "../../../../../services/API";
import { media } from "../../../../../theme";
import { HeaderData } from "../../../../../types/railsTypes";
import Profile from "../../Profile";
import MenuList from "../MenuList";
import MenuItem from "../MenuList/MenuItem";
import Sidebar from "../Sidebar";
import SidebarButton from "../Sidebar/SidebarButton";
import SidebarList from "../Sidebar/SidebarList";
import styled from "styled-components";
import { getSignedCookie } from '../../../../../utils/cookies';

interface IMenuAuthenticated {
  data: HeaderData;
  userId: number;
  translations: {
    languageTitle: string;
    myListTitle: string;
    myListText: string;
    profileTitle: string;
    dataNotice?: string;
  };
}

const MenuAuthenticated: React.FC<IMenuAuthenticated> = ({
  data,
  translations,
  userId,
}) => {
  const { width } = useWindowDimensions();
  const [showSidebar, setShowSidebar] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [userLanguage, setUserLanguage] = useState("");
  const isMediumWidthSize = width <= media.sizes.medium;
  const firstName = data.user_data.find((field) => field.id === "first_name");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const locale = getLocaleValue(params.get("locale"));
    if (locale === "de") {
      setUserLanguage("de");
    } else {
      setUserLanguage("en");
    }
  }, []);

  return (
    <MenuList>
      {isMediumWidthSize ? (
        <>
          <MenuItem title="menu" onClick={() => setShowSidebar(true)} icon="menu" />
          {showSidebar ? (
            <Sidebar handleClose={() => setShowSidebar(false)}>
              <SidebarList>
                <SidebarButton
                  variant="ghost"
                  icon="globe"
                  onClick={() => toggleLanguage(userLanguage)}
                >
                  <SidebarLanguage isBold={userLanguage === "en"}>
                    EN
                  </SidebarLanguage>
                  /
                  <SidebarLanguage isBold={userLanguage === "de"}>
                    DE
                  </SidebarLanguage>
                </SidebarButton>
                <SidebarButton
                  variant="ghost"
                  icon="changelog"
                  onClick={() => {
                    window.location.assign("/profile/favorites");
                  }}
                >
                  My List
                </SidebarButton>
                <SidebarButton
                  variant="ghost"
                  icon="menu-user"
                  onClick={() => {
                    setShowProfile(true);
                    setShowSidebar(false);
                  }}
                >
                  {firstName.value ? firstName.value : "Profile"}
                </SidebarButton>
                <SidebarButton
                  variant="ghost"
                  icon="logout"
                  onClick={async () => {
                    await API.User.signOut();
                    window.location.href = window.location.origin + "/";
                  }}
                >
                  Logout
                </SidebarButton>
              </SidebarList>
            </Sidebar>
          ) : null}
        </>
      ) : (
        <>
          <MenuItem
            title={translations.languageTitle}
            onClick={() => toggleLanguage(userLanguage)}
            text={
              <>
                <Language isBold={userLanguage === "en"}>EN</Language>/
                <Language isBold={userLanguage === "de"}>DE</Language>
              </>
            }
          />
          <MenuItem
            title={translations.myListTitle}
            href="/profile/favorites"
            icon="changelog"
            text={translations.myListText}
          />
          <MenuItem
            title={translations.profileTitle}
            onClick={() => {
              setShowSidebar(false);
              setShowProfile(true);
            }}
            icon="menu-user"
            text={firstName?.value ? firstName.value : "Profile"}
          />
        </>
      )}
      {showProfile ? (
        <Profile
          userId={userId}
          user={data.user_data}
          handleClose={() => setShowProfile(false)}
          translations={translations as any}
        />
      ) : null}
    </MenuList>
  );
};

const changeLanguage = (language: "en" | "de") => {
  const params = new URLSearchParams(window.location.search);
  const locale = getLocaleValue(params.get("locale"));
  switch (language) {
    case "en":
      if (locale === "de") {
        params.set("locale", language);
        window.location.search = params.toString();
      }
      break;
    case "de":
      if (locale !== "de") {
        params.set("locale", language);
        window.location.search = params.toString();
      }
      break;
  }
};

const toggleLanguage = (currentLanguage: string) => {
  if (currentLanguage === "de") {
    changeLanguage("en");
  } else {
    changeLanguage("de");
  }
};

const Language = styled.span<{ isBold: boolean }>`
  ${(props) => props.theme.typography.navigation};
  font-weight: ${(props) => (props.isBold ? "bold" : "normal")};
  position: relative;
  top: 1px;
`;

const getLocaleValue = (paramLocale: string): string =>{
  const localeCookie = getSignedCookie("locale");
  return paramLocale ?? localeCookie;
}

const SidebarLanguage = styled.span<{ isBold: boolean }>`
  font-weight: ${(props) => (props.isBold ? "bold" : "normal")};
`;

export default MenuAuthenticated;
