import styled, { css } from "styled-components";
import Icon from "../Icon";
import { forwardRef } from "react";

interface ISelect {
  items: { id: string; label: string }[];
  label: string;
  defaultValue: string;
  [x: string]: any; //eslint-disable-line
}

const Select: React.FC<ISelect> = ({ items, label, defaultValue, ...rest }, ref) => {
  return (
    <Container>
      <Label htmlFor={label}>{label}</Label>
      <StyledSelect id={label} defaultValue={defaultValue} ref={ref} {...rest}>
        {items.map((item) => (
          <option key={item.id} value={item.id}>
            {item.label}
          </option>
        ))}
      </StyledSelect>
      <StyledIcon variant="navigation-left" />
    </Container>
  );
};

const Container = styled.div`
  display: inline-block;
  position: relative;
  min-width: 144px;
`;

const StyledSelect = styled.select`
  all: unset;
  width: 100%;
  box-sizing: border-box;
  ${(props) => props.theme.typography.copy};

  ${({ theme: { spacing, colors } }) => {
    return css`
      color: ${colors.dark};
      background-color: ${colors.lightestGray};
      border: 1px solid ${colors.darkGray};
      padding: 22px calc(${spacing.s} + 20px + ${spacing.xs}) 10px ${spacing.s};
      border-radius: ${spacing.xxs};

      &:disabled {
        opacity: 0.5;
      }

      &:enabled:hover {
        background-color: ${colors.lightGray};
      }

      &:enabled:active {
        background-color: ${colors.darkGray};
        border: 2px solid ${colors.functionalBlue};
        padding: 21px calc(${spacing.s} + 20px + ${spacing.xs}) 9px ${spacing.s};
      }

      &:enabled:focus {
        background-color: ${colors.lightestGray};
        border: 2px solid ${colors.functionalBlue};
        padding: 21px calc(${spacing.s} + 20px + ${spacing.xs}) 9px ${spacing.s};
      }
    `;
  }}
`;

const Label = styled.label`
  position: absolute;
  ${(props) => props.theme.typography.copySmall};
  color: ${(props) => props.theme.colors.darkestGray};
  top: ${(props) => props.theme.spacing.xxs};
  left: ${(props) => props.theme.spacing.s};
  pointer-events: none;
`;

const StyledIcon = styled(Icon)`
  transform: rotate(-90deg);
  position: absolute;
  width: 20px;
  height: 20px;
  right: ${(props) => props.theme.spacing.s};
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  pointer-events: none;
`;

export default forwardRef<HTMLSelectElement, ISelect>(Select as any);
