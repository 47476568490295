import styled from "styled-components";

interface IGridColumns {
  children: React.ReactNode;
  [x: string]: any; // eslint-disable-line
}

const GridColumns: React.FC<IGridColumns> = ({ children, ...rest }) => {
  return <Columns {...rest}>{children}</Columns>;
};

const Columns = styled.div`
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  column-gap: 12px;
  width: 100%;
`;

export default GridColumns;
