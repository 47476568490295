import styled from "styled-components";
import Icon, { IconType } from "../../../../../../elements/smartevents/Icon/Icon";
import MenuLink from "./MenuLink";

interface IMenuItem {
  icon?: IconType;
  text?: React.ReactNode;
  title: string;
  [x: string]: any; // eslint-disable-line
}

const MenuItem: React.FC<IMenuItem> = ({ icon, text, title, ...rest }) => {
  return (
    <Item>
      <MenuLink {...rest} title={title}>
        {icon ? <MenuIcon variant={icon} /> : null}
        {text ? <MenuText hasIcon={!!icon}>{text}</MenuText> : null}
      </MenuLink>
    </Item>
  );
};

const Item = styled.li`
  ${(props) => props.theme.typography.navigation};
  cursor: pointer;
  -webkit-font-smoothing: antialiased; 
  -moz-osx-font-smoothing: grayscale; 
`;

const MenuIcon = styled(Icon)`
  width: 24px;
  height: 24px;
`;

const MenuText = styled.span<{ hasIcon: boolean }>`
  margin-left: ${(props) => (props.hasIcon ? props.theme.spacing.s : 0)};
  position: relative;
  top: 1px;
`;

export default MenuItem;
