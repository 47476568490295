import { forwardRef } from "react";
import styled, { css } from "styled-components";
import Icon from "../Icon";
import { IconType } from "../Icon/Icon";

type ButtonVariant = "cta" | "outline" | "ghost";

export interface IButton {
  variant: ButtonVariant;
  children?: string;
  icon?: IconType;
  rounded?: boolean;
  [x: string]: any; //eslint-disable-line
}

export type Ref = HTMLButtonElement;

const Button: React.FC<IButton> = (
  { variant, children, icon, rounded, ...rest },
  ref
) => {
  switch (variant) {
    case "cta":
      return (
        <CtaButton {...rest} isRounded={rounded} ref={ref}>
          {icon && <Icon variant={icon} />}
          {children && <ButtonText hasIcon={!!icon}>{children}</ButtonText>}
        </CtaButton>
      );
    case "outline":
      return (
        <OutlineButton {...rest} isRounded={rounded} ref={ref}>
          {icon && <Icon variant={icon} />}
          {children && <ButtonText hasIcon={!!icon}>{children}</ButtonText>}
        </OutlineButton>
      );
    case "ghost":
      return (
        <GhostButton {...rest} ref={ref}>
          {icon && <Icon variant={icon} />}
          {children && <ButtonText hasIcon={!!icon}>{children}</ButtonText>}
        </GhostButton>
      );
    default:
      return null;
  }
};

const sharedStyles = css`
  ${(props) => props.theme.typography.caption};
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
`;

const ButtonText = styled.span<{ hasIcon?: boolean }>`
  margin-left: ${(props) => (props.hasIcon ? "11px" : 0)};
  position: relative;
  top: 1px;
`;

const CtaButton = styled.button<{ isRounded?: boolean }>`
  ${sharedStyles};
  ${({ isRounded, theme: { colors, spacing } }) => {
    return css`
      border: none;
      position: relative;
      background-color: ${colors.primary};
      color: ${colors.light};
      padding: ${isRounded ? `${spacing.s}` : `${spacing.s} 22px`};
      border-radius: ${isRounded ? "9999px" : spacing.xxs};

      &:enabled:hover {
        background-color: hsl(
          ${colors.primaryHue},
          ${colors.primarySaturation},
          calc(${colors.primaryLightness} - 3%)
        );
      }

      &:enabled:active {
        background-color: hsl(
          ${colors.primaryHue},
          ${colors.primarySaturation},
          calc(${colors.primaryLightness} - 7%)
        );
      }

      &:focus {
        border: 2px solid ${colors.functionalBlue};
        padding: ${isRounded
          ? `calc(${spacing.s} - 2px)`
          : `calc(${spacing.s} - 2px) 20px`};
      }

      &:focus::after {
        content: "";
        position: absolute;
        border: 1px solid ${colors.light};
        border-radius: ${isRounded ? "9999px" : spacing.xxxs};
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }

      &:disabled {
        opacity: 0.5;
      }
    `;
  }}
`;

const OutlineButton = styled.button<{ isRounded?: boolean }>`
  ${sharedStyles};
  ${({ isRounded, theme: { colors, spacing } }) => {
    return css`
      color: ${colors.dark};
      background-color: hsla(0, 0%, 0%, 0);
      border: solid 1px ${colors.darkestGray};
      padding: ;
      padding: ${isRounded
        ? `calc(${spacing.s} - 1px)`
        : `calc(${spacing.s} - 1px) 21px`};
      border-radius: ${isRounded ? "9999px" : spacing.xxs};

      &:enabled:hover {
        background-color: hsla(0, 0%, 0%, 0.07);
      }

      &:enabled:active {
        background-color: hsla(0, 0%, 0%, 0.15);
      }

      &:enabled:focus {
        border: solid 2px var(--functional--blue);
        padding: ${isRounded
          ? `calc(${spacing.s} - 2px)`
          : `calc(${spacing.s} - 2px) 20px`};
      }

      &:disabled {
        opacity: 0.5;
      }
    `;
  }}
`;

const GhostButton = styled.button`
  ${sharedStyles};
  ${({ theme: { colors, spacing } }) => {
    return css`
      padding: ${spacing.s} 0;
      color: ${colors.dark};
      background-color: transparent;
      border: none;
      position: relative;

      &:enabled:hover {
        color: ${colors.secondary};
      }

      &:enabled:active {
        color: hsl(
          ${colors.secondaryHue},
          ${colors.secondarySaturation},
          calc(${colors.secondaryLightness} - 7%)
        );
      }

      &:focus::after {
        content: "";
        position: absolute;
        border: 2px solid ${colors.functionalBlue};
        border-radius: ${spacing.xxs};
        top: 0;
        bottom: 0;
        left: -16px;
        right: -16px;
      }

      &:disabled {
        opacity: 0.5;
      }
    `;
  }}
`;

export default forwardRef<Ref, IButton>(Button as any);
