import DOMPurify from "dompurify";
import { useEffect } from "react";
import styled from "styled-components";
import Button from "../../../../../elements/smartevents/Button";
import Modal from "../../../../../elements/smartevents/Modal";
import { CookieConsentIntroTranslations } from "../../../../../types/railsTypes";

interface IIntroModal {
  acceptAllHandler: () => void;
  openSettingsHandler: () => void;
  rejectAllHandler: () => void;
  translations: CookieConsentIntroTranslations;
}

const IntroModal: React.FC<IIntroModal> = ({
  acceptAllHandler,
  openSettingsHandler,
  rejectAllHandler,
  translations,
}) => {
  useEffect(() => {
    const rejectElem = document.querySelector(".modal-cookie-intro__reject-all");

    if (!rejectElem) {
      return;
    }

    rejectElem.addEventListener("click", rejectAllHandler);

    return () => {
      rejectElem.removeEventListener("click", rejectAllHandler);
    };
  }, []);

  return (
    <StyledModal>
      <ModalTitle>{translations.title}</ModalTitle>
      <ModalContent
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(translations.content),
        }}
      />
      <ButtonsContainer>
        <Button type="button" variant="outline" onClick={openSettingsHandler}>
          {translations.changeSettingsButton}
        </Button>
        <Button variant="cta" type="submit" onClick={acceptAllHandler}>
          {translations.acceptAllButton}
        </Button>
      </ButtonsContainer>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  height: max-content;
`;

const ModalTitle = styled.h3`
  ${(props) => props.theme.typography.headline3};
  margin: 0 0 ${(props) => props.theme.spacing.m};
`;

const ModalContent = styled.div`
  ${(props) => props.theme.typography.copySmall};
  p {
    margin: ${(props) => props.theme.spacing.s} 0;
  }
  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.functionalBlue};
  }
  strong {
    font-weight: bold;
  }
  ol {
    padding-left: ${(props) => props.theme.spacing.s};
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing.s};
  justify-content: right;
  margin-top: ${(props) => props.theme.spacing.m};
`;

export default IntroModal;
