import styled from "styled-components";
import Button from "../../../../../../elements/smartevents/Button";

interface ISidebarButton {
  [x: string]: any; // eslint-disable-line
}

const SidebarButton: React.FC<ISidebarButton> = (props) => {
  return <SideButton {...props} />;
};
const SideButton = styled(Button)`
  padding: ${({ theme: { spacing } }) => `calc(${spacing.s} + ${spacing.xs}) 0`};
  width: 100%;
  align-self: flex-start;
  justify-content: flex-start;
  position: relative;
  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGray};
  }
`;

export default SidebarButton;
